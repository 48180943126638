/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, SeparateLineWrap, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Product"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-2x5rq6 --style2 js-anim  --anim2 --left --full" name={"uvod"} fullscreen={true} anim={"2"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--shadow4 fs--102" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">&nbsp; &nbsp;Our Product</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"d6y5lek2iao"} layout={"l6"}>
          
          <SeparateLineWrap style={{"paddingBottom":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="js-anim  --anim6 --center" style={{"paddingTop":0,"paddingBottom":0}} name={"nbse9qwrme"} anim={"6"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--shadow4 title-box--center fs--62" style={{"paddingBottom":26}} content={"Unique Medical Organiser"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s8 --center" style={{"paddingTop":29,"paddingBottom":54}} name={"hkq8fkvy94s"} anim={"2"} animS={"8"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--43" style={{"paddingLeft":0,"maxWidth":""}} content={"&amp; <br>many more in one App"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"s9h0afubru"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingBottom":0,"marginTop":0,"paddingTop":48}} name={"r196bbv1tlj"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style5 title-box--center" content={"Multiplatform"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4" style={{"paddingTop":91,"paddingBottom":68}} name={"hcc11mrc6bw"} anim={"4"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--shadow4 fs--43" content={"Tablet"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/7ea4c838309743a4a402dd4bd157c106_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":355}} srcSet={"https://cdn.swbpg.com/t/38872/7ea4c838309743a4a402dd4bd157c106_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/7ea4c838309743a4a402dd4bd157c106_s=660x_.jpg 660w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":399}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 pb--60" style={{"paddingTop":25}} name={"iz6upqoalmk"} anim={"5"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/a316dc234fb54b939f31873fd8f481b9_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":251}} srcSet={"https://cdn.swbpg.com/t/38872/a316dc234fb54b939f31873fd8f481b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/a316dc234fb54b939f31873fd8f481b9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/a316dc234fb54b939f31873fd8f481b9_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--shadow4 fs--43" content={"Smartphone"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4 pb--60" layout={"l13"} style={{"paddingTop":46}} name={"da048ft5zt"} anim={"4"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--style2 title-box--shadow4 fs--43" content={"Desktop"}>
              </Title>

              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/b60902dd703e4da89e0bb1f53ff56722_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/38872/b60902dd703e4da89e0bb1f53ff56722_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/b60902dd703e4da89e0bb1f53ff56722_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/b60902dd703e4da89e0bb1f53ff56722_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38872/b60902dd703e4da89e0bb1f53ff56722_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38872/b60902dd703e4da89e0bb1f53ff56722_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--02" name={"bottom"} style={{"backgroundColor":"rgba(192, 192, 192, 1)","marginTop":38,"paddingTop":38,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1291,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="--center mb--02 mt--02" style={{"paddingTop":0,"paddingBottom":0,"paddingLeft":0,"paddingRight":0}}>
              
              <Title className="title-box title-box--style2 title-box--center fs--43 swpf--uppercase" content={"<a href=\"/contact\">Contact</a>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":334}} content={"www.smartmedicalpad.com<br>info@smartmedicalpad.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(224, 224, 224, 1)","marginTop":0}} layout={"l1"} name={"kh8dvsp8m4"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--style5 title-box--center fs--26" style={{"paddingTop":8,"paddingBottom":6}} content={"Disclaimer"}>
              </Title>

              <Text className="text-box text-box--center fs--18 mt--0" content={"All patient names and data showed in all pictures&nbsp;on this website are completely imaginary and not real. It serves only to demonstrative purposes."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}